<template>
  <!-- The value of the ‘padding-bottom’ parameter is based on the aspect ratio of the content (in case of a 16:9 video the calculation is 9/16 = 0.5625) -->
<div style="padding-bottom:56.25%; position:relative; display:block; width: 100%">
<iframe width="100%" height="100%"
  src="https://xd.adobe.com/embed/c0385eb4-24c8-4f2c-9ced-84a71a507abb-956b/?fullscreen"
  frameborder="0" allowfullscreen="" style="position:absolute; top:0; left: 0">
</iframe>
</div>

</template>

<script>
import { metaTitle } from '@/constant/data'
export default {
  name: 'investmentInsight',
  metaInfo () {
    return {
      title: metaTitle.investmentInsight
    }
  }
}
</script>
